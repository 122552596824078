.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Game Board!! */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.gameboard-wrapper {
  position: relative;
  text-align: center;
}

.gameboard-overlay {
  position: absolute;
  animation: fade-in 1s linear;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.gameboard-overlay-card {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  min-width: 55%;
  min-height: 55%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0.7);
}

.gameboard {
  position: relative;
  z-index: 1;
}
